/* #generic*/
.text-lg-normal {
  /* Light/LG/LG Normal */
  font-family:
    SF Pro Text,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.text-xs-normal {
  /* Text xs/Normal */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.text-md-medium {
  /* Text md/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.text-lg-bold {
  /* Text lg/Bold */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.text-xl-medium {
  /* Text xl/Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}
.text-xl-semibold {
  /* Text xl/Semibold */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.text-xl-bold {
  /* Text xl/Bold */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
}

.text-lg-medium {
  /* Text lg/Medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.text-sm-semibold {
  color: var(--gray-900, #101828);
  /* Text sm/Semibold */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.btn {
  border-radius: 32px;
  margin: 8px 16px;
  height: 48px;
  border: none;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  z-index: 1;
}

.next-btn {
  margin: 24px 0 0;
  background: #000;
  color: #fff;
  width: calc(100% - 32px) !important;
  max-width: 568px;
}

.next-btn-name {
  margin: 24px 0 0;
  background: #000;
  color: #fff;
  width: 100%;
  max-width: 568px;
}

.next-btn-name:hover,
.next-btn:hover {
  color: #fff !important;
  background-color: #000 !important;
}

/* #index */
.root {
  height: 100%;

  .visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
    min-height: 100%;
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s 1s,
      opacity 1s linear;
    height: 100%;
  }

  .display-none {
    display: none;
  }
}

.splash-screen-wrapper {
  background: var(
    --Background-Gradient---Light,
    linear-gradient(
      348deg,
      rgba(251, 194, 235, 0.6) 0%,
      rgba(166, 193, 238, 0.8) 100%,
      #c7ddff 100%
    ),
    #fff
  );
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  .splash-screen-logo-wrapper {
    display: flex;
    margin-top: 20px;
  }

  .footer-text {
    margin-bottom: 20px;
  }

  .logo {
    width: 95px;
    height: 64px;
  }

  .first-splash-img {
    position: absolute;
    top: 0;
    right: 0;
  }

  .second-splash-img {
    position: absolute;
    bottom: 107px;
    left: 0;
  }

  .third-splash-img {
    position: absolute;
    bottom: 107px;
    right: 0;
  }
}

.registration-fallback-root {
  background: var(
    --Background-Gradient---Light,
    linear-gradient(
      348deg,
      rgba(251, 194, 235, 0.6) 0%,
      rgba(166, 193, 238, 0.8) 100%,
      #c7ddff 100%
    ),
    #fff
  );
  height: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .fallback-btn {
    display: none;
    border: 1px solid var(--gray-300, #d0d5dd);
    color: #333;
    background: #fff;
    border-radius: 32px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 358px;
  }

  .show-btn {
    display: block;
  }
}
