body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='text'] {
  font-size: 16px;
  line-height: 24px;
}

html,
body,
#root,
.App,
.ant-layout {
  height: 100%;
}

.capitalize-first-letter {
  text-transform: capitalize;
}
/* body {
  background: var(
    --Background-Gradient---Light,
    linear-gradient(
      348deg,
      rgba(251, 194, 235, 0.6) 0%,
      rgba(166, 193, 238, 0.8) 100%,
      #c7ddff 100%
    ),
    #fff
  );
} */
