.replenish-suggestions-table {
  width: 70vw;
}

.replenish-suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.replenish-suggestions-table .ant-table-row.expanded-row,
.replenish-suggestions-table .ant-table-expanded-row thead {
  background-color: antiquewhite;
  font-weight: bold;
}

.replenish-suggestions-table .ant-table-expanded-row {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  font-weight: bold;
}

.replenish-suggestions-table .ant-table-expanded-row .ant-table {
  background-color: #fff;
  border-start-start-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.replenish-suggestions-table
  .ant-table-expanded-row
  .ant-table-thead
  > tr
  > th {
  background-color: #fafafa;
  font-weight: bold;
}

.replenish-suggestions-table
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding: 8px 16px;
}

.past-due-row {
  color: #999;
}

.logo {
  justify-self: center;
}
